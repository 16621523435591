<template>
  <div class="register">
      <div class="title">
<img class="img" src="../assets/img/logo.png" alt="" >
<!-- <img src="" alt=""> -->
      </div>
      <div class="registerbox">
          
      <el-row type="flex" class="row-bg" justify="end">
  <el-col :span="19">
       <el-card class="box-card">
  <div slot="header" class="clearfix">
    <span>用户注册</span>
    <el-button style="float: right; padding: 3px 0" type="text" @click="tologin">返回登录</el-button>
  </div>
  <el-form ref="form" :rules="rules" :inline="true" :model="form" label-width="140px" class="form">
            <el-form-item label="单位名称" prop="j_dwmc">
            <el-input placeholder="请输入内容" v-model="form.j_dwmc" class="input-with-select">
            <el-select v-model="form.j_nature" slot="prepend" @change="isgeti" class="danwei" placeholder="单位性质">
            <el-option label="企业" value="1"></el-option>
            <el-option label="合作社" value="2"></el-option>
            <el-option label="种植户" value="3"></el-option>
            </el-select>
            </el-input>
            </el-form-item>
            <el-form-item label="单位类型" >
                  <el-checkbox-group v-model="form.qylx">
                <el-checkbox-button v-for="qy in qyclass" :label="qy.id" :key="qy.id">{{qy.name}}</el-checkbox-button>
                </el-checkbox-group>  
            </el-form-item>
            <el-form-item label="社会统一信用代码">
    <el-input v-model="form.j_licence" :disabled="licence"></el-input>
  </el-form-item>
  <el-form-item label="组织机构代码">
    <el-input v-model="form.j_organization" :disabled="organization"></el-input>
  </el-form-item>
  <el-form-item label="法人姓名">
    <el-input v-model="form.j_legal" :disabled="legal"></el-input>
  </el-form-item>
  <el-form-item label="所在区域" prop="j_area">
            
                 <el-cascader
      :props="props"
      @change="handleChange"
      v-model="form.selectedOptions"
      style="width: 300px"
    ></el-cascader>
            
            
            </el-form-item>
  
  <el-form-item label="负责人" prop="j_name" >
    <el-input v-model="form.j_name"></el-input>
  </el-form-item>
  <el-form-item label="身份证号" prop="j_idnum">
    <el-input v-model="form.j_idnum"></el-input>
  </el-form-item>
  <el-form-item label="手机号码" prop="j_mobile">
    <el-input v-model="form.j_mobile"></el-input>
  </el-form-item>
  <el-form-item label="电子邮箱" prop="j_email">
    <el-input v-model="form.j_email"></el-input>
  </el-form-item>
   <el-divider></el-divider>
    <el-form-item label="注册资本">
    <el-input v-model="form.j_zczb"></el-input>
  </el-form-item>
  <el-form-item label="固定资本">
    <el-input v-model="form.j_gdzb"></el-input>
  </el-form-item>
  <el-form-item label="从业人数">
    <el-input v-model="form.j_cyrs"></el-input>
  </el-form-item>
  <el-form-item label="经营范围">
    <el-input v-model="form.j_jyfw"></el-input>
  </el-form-item>
  <el-form-item label="联系地址" prop="j_dwdz">
    <el-input v-model="form.j_dwdz"></el-input>
  </el-form-item>
  <el-form-item label="邮政编码" prop="j_yzbm">
    <el-input v-model="form.j_yzbm"></el-input>
  </el-form-item>
  <el-form-item label="单位传真">
    <el-input v-model="form.j_dwcz"></el-input>
  </el-form-item>
  <el-form-item label="单位网站">
    <el-input v-model="form.j_dwmz"></el-input>
  </el-form-item>
    <el-form-item label="单位简介" class="jianjie">
    <el-input type="textarea" class="jian" v-model="form.j_profile"></el-input>
  </el-form-item>
        </el-form>
        <!-- <el-form-item> -->
            <div style="text-align:center;">
                <el-button type="primary" @click="onSubmit" @keydown="addByEnterKey">立即创建</el-button>
                <el-button @click="tologin">取消</el-button>
            </div>
                
            <!-- </el-form-item> -->
</el-card>
        
      



  </el-col>
  
</el-row>
</div>
     
  </div>
</template>

<script>
import { regionDataPlus,CodeToText  } from 'element-china-area-data'

export default {
    data(){
        return{
          tree:[],
          props:{
            lazy: true,
        lazyLoad: (node, resolve) => {
          const { level } = node;
          const nodes = [];
          // level: 层级
          // node 节点数据
          // 一级菜单数据
        
          // 为1代表第一次请求
          let type = level == 0 ? "1" : node.value;
          this.provinceFn(type)
            .then((res) => {
              if (res.data.code == -1) {
                this.msgFn("error", res.message);
                return;
              }
              // 节点数组
             
              res.data.data.map((item) => {
                // {value:'',label:'全部'}
                let obj = {
                  value: item.code,
                  label: item.name,
                  leaf: node.level >= 4, 
                };
                nodes.push(obj);
                
              });
              // resolve 节点返回
              resolve(nodes);
              
            })
            .catch((error) => {
              console.log(error);
            });
        }

          },
           
            options: regionDataPlus,
            selectedOptions: [],
            select:'',
            input3:'',
            licence:false,
            organization:false,
            legal:false,
            city:'',
            qyclass:[
                {
                    name:"生产",
                    id:1
                },
                 {
                    name:"加工",
                    id:2
                },
                 {
                    name:"销售",
                    id:3
                },
            ],
            rules:{
            j_nature: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
          j_area:[
            { required: true, message: '请选择所在区域', trigger: 'blur' }
          ],
          j_type: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
          j_dwmc: [
            { required: true, message: '请输入单位名称', trigger: 'change' }
          ],
          j_idnum: [
            { required: true, message: '请输入身份证号码', trigger: 'change' }
          ],
          j_mobile: [
            { required: true, message: '请输入手机号', trigger: 'change' }
          ],
          j_email: [
            { required: true, message: '请输入邮箱', trigger: 'change' }
          ],
          
          j_name: [
            { required: true, message: '请输入姓名', trigger: 'change' }
          ],
           j_dwdz: [
            { required: true, message: '请输入单位地址', trigger: 'change' }
          ],
          j_yzbm: [
            { required: true, message: '请输入姓名', trigger: 'change' }
          ],
          },
            form:{
              selectedOptions:[],
                qylx:[],
                j_nature:'',
                j_type:'',
                j_dwmc:'',
                j_licence:'',
                j_organization:'',
                j_legal:'',
                j_name:'',
                j_idnum:'',
                j_mobile:'',
                j_email:'',
                j_zczb:'',
                j_gdzb:'',
                j_cyrs:'',
                j_jyfw:'',
                j_dwdz:'',
                j_yzbm:'',
                j_dwcz:'',
                j_dwmz:'',
                j_profile:'',
                j_area:'',
                j_address:''



            }

        }
    },
    methods:{
      addByEnterKey(e){
        if(e.keyCode==13){
          this.onSubmit();
        }
      },
            // 获取省市区街道
    provinceFn(id) {
      let data = {
        up_id: id,
      };
      // 此处是一个promise 直接返回
      return this.$http.post('getcity',data);
    },
    // 监听数据变化
   async handleChange(value) {
      console.log(value[4]);
      const {data:res} =await this.$http.post("codeToText",{"up_id":value[4]})
      console.log(res.data.address)
      this.form.j_area=res.data.address
      
    },
         onSubmit(){
          this.$refs.form.validate(async valid => {
            if (valid) {
            this.form.j_area=this.city
            
            this.form.qylx.forEach(item => {
              this.form.j_type=this.form.j_type+item+','
            });
            this.form.j_type=this.form.j_type.substring(0,this.form.j_type.length-1)
            console.log(this.form)
            const {data:res} = await this.$http.post('register',this.form)
            if(res.code==0){
              this.$message({
                type:'success',
                message:'注册成功，初始密码为123456'
              })
              const result = await this.$http.post('dologin',{'user_login':this.form.j_email,'pwd':'123456'})
              if(result.data.code==0){
                sessionStorage.setItem("token",result.data.data.token)
              sessionStorage.setItem('auth',result.data.data.auth)
              sessionStorage.setItem("qyinfo",JSON.stringify(result.data.data.qyinfo))
             
              this.$router.push('/company/bigdata').catch(err=>{console.log(err)})
              }
            }
          } else {
            console.log('error submit!!');
            return false;
          }
           });
            
        },
        tologin(){
            this.$router.push('/login')
        },
        isgeti(e){
            if(e==3){
                this.licence=true
                this.organization=true
                this.legal=true
            }else{
                this.licence=false
                this.organization=false
                this.legal=false
            }
        }
    }

}
</script>

<style lang="less" scoped>

.form{
    background-color: #fff;
    width: 1200px;
    
}
.danwei{
    width: 200px;
}
.box-card {
    width: 1200px;
  }
.title{
    text-align: center;
}
.img {
        height: 114px;
    }

.el-select .el-input {
    width: 200px;
  }
  .el-form-item{
      width: 560px;
      margin-bottom: 15px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
 .register {
        height: 100%;
        background: url() bottom center no-repeat;
        background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
        background-image: linear-gradient(0deg,#30cfd0 0,#330867);
        // background-image: ;
        // url("../assets/img/zz.png"),
        background-size: 100% 100%;
    }
    .jianjie{
        width: 800px;
    }
    .jian{
        width: 600px;
    }
</style>